import dayjs from 'dayjs'

export const messageTypes = [
    {
        type: 'NewRegistrationDocumentNotification',
        title: notification => `${notification.data.causer_name}`,
        singleMessage: notification => `${notification.data.causer_name} a mis en ligne un nouveau document`,
        groupedMessage: notifications => `${notifications[0].data.causer_name} a mis en ligne ${notifications.length} nouveaux documents`,
        link: notification => `/babies/${notification.data.baby_uuid}/documents/`
    },
    {
        type: 'NewThreadCommentNotification',
        title: notification => `${notification.data.causer_name}`,
        singleMessage: notification => `${notification.data.message}`,
        groupedMessage: notifications => `${notifications.length} nouveaux commentaires sur vos publications`,
        link: notification => `/communications/social?thread=${notification.data.thread_uuid}&comment=${notification.data.comment_uuid}`
    },
    {
        type: 'ClassroomPrevisionExecutedNotification',
        title: notification => 'Changement automatique de section',
        singleMessage: notification => `${notification.data.baby_name} a été déplacé dans la section ${notification.data.classroom}`,
        groupedMessage: notifications => `${notifications.length} enfants ont changé de section`,
        link: notification => `/babies/${notification.data.baby_uuid}/classroom`
    },
    {
        type: 'ParentUploadedAttendanceDocumentNotification',
        title: notification => `${notification.data.causer_name}`,
        singleMessage: notification => `Nouveau certificat médical pour ${notification.data.baby_name}, période ${dayjs(notification.data.from).format('DD/MM/YYYY')} - ${dayjs(notification.data.to).format('DD/MM/YYYY')}`,
        groupedMessage: notifications => `${notifications.length} nouveaux certificats médicaux`,
        link: notification => `/babies/${notification.data.baby_uuid}/medical?tab=documents`
    },
    {
        type: 'EventReminderNotification',
        title: notification => `Rappel de ${notification.data.causer_name}`,
        singleMessage: (notification) => {
            if (notification.data.registrations_uuid.length) {
                return `Pour ${notification.data.babies.join(', ')}: ${notification.data.description}`
            }
            return `${notification.data.description}`
        },
        groupedMessage: notifications => `${notifications.length} rappels`,
        link: notification => null
    },
    {
        type: 'RegistrationRefused',
        title: notification => notification.data.causer_name ? `${notification.data.causer_name} a annulé l'inscription` : 'Inscription automatiquement refusée',
        singleMessage: notification => notification.data.causer_name ? `${notification.data.causer_name} a choisi d'annuler l'inscription de ${notification.data.baby_name} via le mail de rappel` : `L'inscription de ${notification.data.baby_name} a été automatiquement annulée car le parent a ignorer le mail de rappel`,
        groupedMessage: notifications => `${notifications.length} inscriptions refusées automatiquement`,
        link: notification => `/registrations/${notification.data.registration_uuid}`
    },
    {
        type: 'NewWebRegistrationNotification',
        title: notification => 'Nouvelle demande d\'inscription en ligne',
        singleMessage: notification => `Un parent a utilisé le formulaire en ligne pour inscrire son enfant ${notification.data.baby_name}`,
        groupedMessage: notifications => `${notifications.length} nouvelles inscriptions en ligne`,
        link: notification => `/registrations/${notification.data.registration_uuid}`
    },
    {
        type: 'NewParentDemandNotification',
        title: notification => 'Nouvelle demande',
        singleMessage: notification => `${notification.data.causer_name} a soumis ${{
            registration_new_reservation: 'une demande de journée supplémentaire',
            registration_new_holiday: 'une demande de congé',
            registration_medical_certificates: 'un certificat médical',
            registration_measure: 'une nouvelle mesure'
        }[notification.data.type] || 'une nouvelle demande'} pour ${notification.data.baby_name}`,
        groupedMessage: notifications => `${notifications.length} nouvelles demandes à vérifier.`,
        link: notification => '/demands'
    }
]
